import React from 'react'
import moment from 'moment'
import { StaticQuery, graphql } from 'gatsby'

import {
  Box,
  Button,
  DisplayText,
  FlexCol,
  FlexGrid,
  H3,
  Highlight,
  SEO,
  Text,
} from 'src/components'

const EventItem = ({
  linkTo,
  image,
  heading,
  body,
  date,
  button,
  color,
  ...props
}) => (
  <Box width={1} {...props}>
    <Box bg={'brand.' + color.toLowerCase()}>
      <FlexGrid>
        <FlexCol width={[1, null, 1 / 3]}>
          <Box
            as="a"
            href={linkTo}
            target="_blank"
            position="relative"
            display="block"
            pb="calc(2/3 * 100%)"
            bg="bg.alt"
            backgroundImage={image ? 'url(' + image + ')' : ''}
            backgroundSize="cover"
            backgroundPosition="center"
          >
            {/* <Img
              src={image}
              style={{ ...cover(), height: '100%', userSelect: 'none' }}
            /> */}
          </Box>
        </FlexCol>
        <FlexCol width={[1, null, 2 / 3]}>
          <FlexCol height="100%" p={3}>
            <H3
              children={heading}
              as="a"
              href={linkTo}
              target="_blank"
              position="relative"
              display="block"
              mb="0.25em"
              style={{ textDecoration: 'none' }}
            />
            <Text children={body} mb="0.25em" />
            <Text children={date} mb="1em" color="rgba(0, 0, 0, 0.5)" />
            <Box mt="auto">
              <Button
                children={button}
                as="a"
                href={linkTo}
                target="_blank"
                rel="noopener noreferrer"
              />
            </Box>
          </FlexCol>
        </FlexCol>
      </FlexGrid>
    </Box>
  </Box>
)

const Events = () => (
  <StaticQuery
    query={graphql`
      query {
        events: allPrismicEvent {
          edges {
            node {
              id
              dataString
            }
          }
        }
      }
    `}
    render={data => {
      let events = []
      data.events &&
        data.events.edges.forEach(event => {
          events.push(JSON.parse(event.node.dataString))
        })
      return (
        <>
          <SEO title={'Events | Ssubi Foundation - Hope is what we have'} />
          <Box px={[3, null, null, 4]} py={4} bg="bg.default">
            <Box maxWidth={1280} mx="auto">
              <FlexCol alignItems="center">
                <DisplayText
                  mb="0.5em"
                  textAlign="center"
                  style={{ transform: 'rotate(-7.5deg)' }}
                >
                  <Highlight variant={1}>Events</Highlight>
                </DisplayText>
              </FlexCol>
            </Box>
          </Box>
          <Box px={[2, null, 3, 4]} py={[4, 5]}>
            <Box maxWidth={1280} mx="auto">
              {events.length === 0 && (
                <H3
                  children={`There's no events planned right now. Check back throughout the year to see our events!`}
                  textAlign="center"
                />
              )}
              {events.length > 0 && (
                <FlexGrid gutterY={[3]}>
                  {events.map((event, index) => (
                    <EventItem
                      image={event.event_image.url}
                      heading={event.event_title[0].text}
                      body={event.event_description}
                      date={moment(event.event_date_time).format(
                        'h:mm A MMMM Do, YYYY'
                      )}
                      button={event.event_button_text}
                      linkTo={event.event_link}
                      key={event.id}
                      color={event.event_color}
                    />
                  ))}
                </FlexGrid>
              )}
            </Box>
          </Box>
        </>
      )
    }}
  />
)

export default Events
